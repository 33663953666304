import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import RefDataDate from '../components/RefDataDate';
import Mapbox from '../mapbox'
import LegendCard from '../mapbox/LegendCard'
import Calculate from '../util/calculate';
import ImageBG from '/images/cct.webp';
import logodmc from '/images/logodmc.png';
import home10 from '/images/home10.png';
import logoe from '/images/picture-opec-2-removebg-preview 1.png';
import home8 from '/images/home8.png';
import home11 from '/images/home11.png';
import logobkk from '/images/logo-bkk.png';
import { Select, Table } from 'antd';
import children_oosc from '/images/cct-2.png';
import image_school from '/images/cct-3.png';
import money from '/images/cct-1.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TumbonAddr from '../util/TumbonAddr'
import useTranslation from '../hook/useTranslation'
import { numberWithCommas } from '../util'
import Chart from '../components/Chart';
import marker_obec from '/images/marker/dot-obec.svg'
import marker_dla from '/images/marker/dot-dla.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'
import marker_opec from '/images/marker/dot-opec.svg'
import marker_bkk from '/images/marker/dot-bkk.svg'
import api from '../util/api';
import getProvinceName from '../util/getProvinceName'
import './index.css';

const { Option } = Select;

const map_key_group = {
  'country_TH': 'จังหวัด',
  'province_TH': 'อำเภอ',
  'city_TH': 'ตำบล',
  'country_EN': 'province',
  'province_EN': 'district',
  'city_EN': 'subdistrict',
}


const PageCCT = (props) => {
  const [loading, setLoading] = useState(true)
  const [T, setLang, lang] = useTranslation()
  const [data, setData] = useState({
    dataChart: [],
    tun_stdent: 0,
    join_school: 0,
    dataTable: []
  })
  const [dataTableCCT, setDataTableCCT] = React.useState(null)
  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: 'country',
    year: "2567",
    semester: "1",
    sungkud: "ทั้งหมด",
    educlass: "ทั้งหมด",
    jontype: "exjon",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });
  const [user_roles, setUserRoles] = useState(null)
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);
  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null
  });

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let [
      cct_fund,
      res25612,
      res25621,
      res25622,
      res25631,
      res25632,
      res25641,
      res25642,
      res25651,
      res25652,
      res25661,
      res25662,
      res25671,
    ] = await Promise.all([
      api.getInitData('cct'),
      api.getInitData('call-fullmap-cct-2561-2'),
      api.getInitData('call-fullmap-cct-2562-1'),
      api.getInitData('call-fullmap-cct-2562-2'),
      api.getInitData('call-fullmap-cct-2563-1'),
      api.getInitData('call-fullmap-cct-2563-2'),
      api.getInitData('call-fullmap-cct-2564-1'),
      api.getInitData('call-fullmap-cct-2564-2'),
      api.getInitData('call-fullmap-cct-2565-1'),
      api.getInitData('call-fullmap-cct-2565-2'),
      api.getInitData('call-fullmap-cct-2566-1'),
      api.getInitData('call-fullmap-cct-2566-2'),
      api.getInitData('call-fullmap-cct-2567-1')
    ])
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        res25612.data = res25612.data.filter(item => item.province_id === pid)
        res25621.data = res25621.data.filter(item => item.province_id === pid)
        res25622.data = res25622.data.filter(item => item.province_id === pid)
        res25631.data = res25631.data.filter(item => item.province_id === pid)
        res25632.data = res25632.data.filter(item => item.province_id === pid)
        res25641.data = res25641.data.filter(item => item.province_id === pid)
        res25642.data = res25642.data.filter(item => item.province_id === pid)
        res25651.data = res25651.data.filter(item => item.province_id === pid)
        res25652.data = res25652.data.filter(item => item.province_id === pid)
        res25661.data = res25661.data.filter(item => item.province_id === pid)
        res25662.data = res25662.data.filter(item => item.province_id === pid)
        res25671.data = res25671.data.filter(item => item.province_id === pid)
        cct_fund = {
          school_jon100: res25671.data.reduce((acc, current) => acc + current.school_jon100, 0),
          obec_school: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + 1 : acc + 0, 0),
          bpp_school: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + 1 : acc + 0, 0),
          dla_school: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + 1 : acc + 0, 0),
          opec_school: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + 1 : acc + 0, 0),
          onab_school: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + 1 : acc + 0, 0),
          bkk_school: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + 1 : acc + 0, 0),

          obec_student: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + current.total_student : acc + 0, 0),
          bpp_student: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + current.total_student : acc + 0, 0),
          dla_student: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + current.total_student : acc + 0, 0),
          opec_student: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + current.total_student : acc + 0, 0),
          onab_student: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + current.total_student : acc + 0, 0),
          bkk_student: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + current.total_student : acc + 0, 0),

          obec_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + current.total_exjon : acc + 0, 0),
          bpp_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + current.total_exjon : acc + 0, 0),
          dla_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + current.total_exjon : acc + 0, 0),
          opec_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + current.total_exjon : acc + 0, 0),
          onab_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + current.total_exjon : acc + 0, 0),
          bkk_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + current.total_exjon : acc + 0, 0),

          obec_k_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + current.k_exjon : acc + 0, 0),
          obec_p_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + current.p_exjon : acc + 0, 0),
          obec_m_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + current.m_exjon : acc + 0, 0),
          obec_h_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สพฐ.' ? acc + current.h_exjon : acc + 0, 0),

          bpp_k_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + current.k_exjon : acc + 0, 0),
          bpp_p_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + current.p_exjon : acc + 0, 0),
          bpp_m_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + current.m_exjon : acc + 0, 0),
          bpp_h_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'ตชด.' ? acc + current.h_exjon : acc + 0, 0),

          dla_k_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + current.k_exjon : acc + 0, 0),
          dla_p_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + current.p_exjon : acc + 0, 0),
          dla_m_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + current.m_exjon : acc + 0, 0),
          dla_h_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'อปท.' ? acc + current.h_exjon : acc + 0, 0),

          opec_k_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + current.k_exjon : acc + 0, 0),
          opec_p_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + current.p_exjon : acc + 0, 0),
          opec_m_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + current.m_exjon : acc + 0, 0),
          opec_h_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'สช.' ? acc + current.h_exjon : acc + 0, 0),

          onab_k_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + current.k_exjon : acc + 0, 0),
          onab_p_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + current.p_exjon : acc + 0, 0),
          onab_m_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + current.m_exjon : acc + 0, 0),
          onab_h_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'พศ.' ? acc + current.h_exjon : acc + 0, 0),

          bkk_k_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + current.k_exjon : acc + 0, 0),
          bkk_p_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + current.p_exjon : acc + 0, 0),
          bkk_m_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + current.m_exjon : acc + 0, 0),
          bkk_h_exjon: res25671.data.reduce((acc, current) => current.sungkud === 'กทม.' ? acc + current.h_exjon : acc + 0, 0),

          total_school: res25671.data.reduce((acc, current) => acc + 1, 0),
          total_student: res25671.data.reduce((acc, current) => acc + current.total_student, 0),
          total_jon: res25671.data.reduce((acc, current) => acc + current.total_jon, 0),
          total_exjon: res25671.data.reduce((acc, current) => acc + current.total_exjon, 0),
        }

        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })

      }
    }

    let obj = {
      k_cct: cct_fund.obec_k_exjon,
      p_cct: cct_fund.obec_p_exjon,
      m_cct: cct_fund.obec_m_exjon,
      h_cct: cct_fund.obec_h_exjon,

      k_bpp: cct_fund.bpp_k_exjon,
      p_bpp: cct_fund.bpp_p_exjon,
      m_bpp: cct_fund.bpp_m_exjon,
      h_bpp: cct_fund.bpp_h_exjon,

      k_dla: cct_fund.dla_k_exjon,
      p_dla: cct_fund.dla_p_exjon,
      m_dla: cct_fund.dla_m_exjon,
      h_dla: cct_fund.dla_h_exjon,

      k_opec: cct_fund.opec_k_exjon,
      p_opec: cct_fund.opec_p_exjon,
      m_opec: cct_fund.opec_m_exjon,
      h_opec: cct_fund.opec_h_exjon,

      k_onab: cct_fund.onab_k_exjon,
      p_onab: cct_fund.onab_p_exjon,
      m_onab: cct_fund.onab_m_exjon,
      h_onab: cct_fund.onab_h_exjon,

      k_bkk: cct_fund.bkk_k_exjon,
      p_bkk: cct_fund.bkk_p_exjon,
      m_bkk: cct_fund.bkk_m_exjon,
      h_bkk: cct_fund.bkk_h_exjon,
    }

    let dataChart = [
      {
        nameTH: T('CCT'),
        nameEN: 'CCT',
        color: '#FC8E44',
        data: [obj.k_cct, obj.p_cct, obj.m_cct, obj.h_cct]
      }, {
        nameTH: T('DLA'),
        nameEN: 'DLA',
        color: '#E289F2',
        data: [obj.k_dla, obj.p_dla, obj.m_dla, obj.h_dla]
      }, {
        nameTH: T('BPP'),
        nameEN: 'BPP',
        color: '#048967',
        data: [obj.k_bpp, obj.p_bpp, obj.m_bpp, obj.h_bpp]
      }, {
        nameTH: T('OPEC'),
        nameEN: 'OPEC',
        color: '#FDC500',
        data: [obj.k_opec, obj.p_opec, obj.m_opec, obj.h_opec]
      }, {
        nameTH: T('ONAB'),
        nameEN: 'ONAB',
        color: '#0088FF',
        data: [obj.k_onab, obj.p_onab, obj.m_onab, obj.h_onab]
      }, {
        nameTH: T('BKK'),
        nameEN: 'BKK',
        color: '#00754b',
        data: [obj.k_bkk, obj.p_bkk, obj.m_bkk, obj.h_bkk]
      }
    ]

    let school_obec_exjon = res25671.data.filter(item => item.total_exjon !== 0 && item.sungkud === 'สพฐ.').length
    let school_dla_exjon = res25671.data.filter(item => item.total_exjon !== 0 && item.sungkud === 'อปท.').length
    let school_bpp_exjon = res25671.data.filter(item => item.total_exjon !== 0 && item.sungkud === 'ตชด.').length
    let school_opec_exjon = res25671.data.filter(item => item.total_exjon !== 0 && item.sungkud === 'สช.').length
    let school_onab_exjon = res25671.data.filter(item => item.total_exjon !== 0 && item.sungkud === 'พศ.').length
    let school_bkk_exjon = res25671.data.filter(item => item.total_exjon !== 0 && item.sungkud === 'กทม.').length
    let dataTable = [
      { sungkud: T('CCT'), school: school_obec_exjon, student: cct_fund.obec_exjon },
      { sungkud: T('DLA'), school: school_dla_exjon, student: cct_fund.dla_exjon },
      { sungkud: T('BPP'), school: school_bpp_exjon, student: cct_fund.bpp_exjon },
      { sungkud: T('OPEC'), school: school_opec_exjon, student: cct_fund.opec_exjon },
      { sungkud: T('ONAB'), school: school_onab_exjon, student: cct_fund.onab_exjon },
      { sungkud: T('BKK'), school: school_bkk_exjon, student: cct_fund.bkk_exjon },
    ]

    setData({
      key_group: 'province_name',
      dataTable,
      dataChart,
      tun_stdent: cct_fund.total_exjon.toLocaleString("en-US"),
      join_school: cct_fund.total_school.toLocaleString("en-US"),
      data25612: res25612.data,
      data25621: res25621.data,
      data25622: res25622.data,
      data25631: res25631.data,
      data25632: res25632.data,
      data25641: res25641.data,
      data25642: res25642.data,
      data25651: res25651.data,
      data25652: res25652.data,
      data25661: res25661.data,
      data25662: res25662.data,
      data25671: res25671.data,
    })
    setLoading(false)
  }, [])

  // useEffect(async () => {
  // setData({
  //   key_group: 'province_name',
  //   dataTable,
  //   dataChart,
  //   tun_stdent: cct_fund.total_exjon.toLocaleString("en-US"),
  //   join_school: cct_fund.total_school.toLocaleString("en-US"),
  //   data25612: res25612.data,
  //   data25621: res25621.data,
  //   data25622: res25622.data,
  //   data25631: res25631.data,
  //   data25632: res25632.data,
  //   data25641: res25641.data,
  //   data25642: res25642.data,
  //   data25651: res25651.data,
  //   data25652: res25652.data,
  // })
  // setLoading(false)
  // }, [])

  useEffect(() => {
    if (loading && !data[`data${filter.year}${filter.semester}`]) return;
    let result = {}
    let key_group = ''
    let school = 0
    if (data[`data${filter.year}${filter.semester}`]) {
      let _data = data[`data${filter.year}${filter.semester}`]

      if (filter.sungkud !== 'ทั้งหมด') _data = _data.filter(item => item.sungkud === filter.sungkud)

      if (filter.view === 'country') {
        key_group = 'province_name'
        _data = _data.map(x => ({ ...x, type: 'province_name' }))
      }

      if (filter.view === 'province') {
        key_group = 'city_name'
        _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
      }

      if (filter.view === 'city') {
        key_group = 'tumbon_name'
        _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name)
          .map(x => ({ ...x, type: 'tumbon_name' }))
      }

      _data.forEach(element => {
        school += 1
        if (!result[element[key_group]]) {
          result[element[key_group]] = {
            key: element[key_group],
            school: 1,
            student: element[`total_student`],
            student_target: element[`total_target`],
            student_jon: element[`total_jon`],
            student_exjon: element[`total_exjon`],
            sum_jon_exjon: (element[`total_jon`] + element[`total_exjon`]),
          }

        } else {
          result[element[key_group]].school += 1
          result[element[key_group]].student += element[`total_student`]
          result[element[key_group]].student_target += element[`total_target`]
          result[element[key_group]].student_jon += element[`total_jon`]
          result[element[key_group]].student_exjon += element[`total_exjon`]
          result[element[key_group]].sum_jon_exjon += (element[`total_jon`] + element[`total_exjon`])
        }
      })
      setDataTableCCT([...Object.values(result)])
    }
  }, [filter, loading])

  useEffect(() => {
    if (loading || !user_roles) return;
    let arr_map = []
    let dataContext = data['data' + filter.year + filter.semester]
    let response = onProcessData({
      sungkud: filter['sungkud'],
      educlass: filter['educlass'],
      jontype: filter['jontype'],
      province: filter['province_name'],
      city: filter['city_name'],
      tumbon: filter['tumbon_name'],
      layer: filter['view']
    }, convertMapContent(filter, dataContext))
    if (filter['view'] === 'city') {
      let data_filter = []
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
          data_filter = [...new Set(dataContext.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
        } else {
          data_filter = [...new Set(dataContext.filter(item => item.province_name === filter.province_name))]
        }
      }
      arr_map.push({ id: 'border', layer: filter.view, data: response.data })
      if (user_roles.includes('public') && !user_roles.includes('public-login')) {
        arr_map.push(
          { id: 'school_obec', layer: 'marker', data: [], icon: marker_obec },
          { id: 'school_dla', layer: 'marker', data: [], icon: marker_dla },
          { id: 'school_bpp', layer: 'marker', data: [], icon: marker_bpp },
          { id: 'school_opec', layer: 'marker', data: [], icon: marker_opec },
          { id: 'school_onab', layer: 'marker', data: [], icon: marker_onab },
          { id: 'school_bkk', layer: 'marker', data: [], icon: marker_bkk },
        )
      } else {
        arr_map.push(
          { id: 'school_obec', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'สพฐ.').map(item => ({ ...item, type: 'school_obec', fund: 'cct' })), icon: marker_obec },
          { id: 'school_dla', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'อปท.').map(item => ({ ...item, type: 'school_dla', fund: 'cct' })), icon: marker_dla },
          { id: 'school_bpp', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'ตชด.').map(item => ({ ...item, type: 'school_bpp', fund: 'cct' })), icon: marker_bpp },
          { id: 'school_opec', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'สช.').map(item => ({ ...item, type: 'school_opec', fund: 'cct' })), icon: marker_opec },
          { id: 'school_onab', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'พศ.').map(item => ({ ...item, type: 'school_onab', fund: 'cct' })), icon: marker_onab },
          { id: 'school_bkk', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'กทม.').map(item => ({ ...item, type: 'school_bkk', fund: 'cct' })), icon: marker_bkk },
        )
      }
    } else {
      arr_map.push({ id: 'border', layer: filter.view, data: response.data })
      arr_map.push(
        { id: 'school_obec', layer: 'marker', data: [], icon: marker_obec },
        { id: 'school_dla', layer: 'marker', data: [], icon: marker_dla },
        { id: 'school_bpp', layer: 'marker', data: [], icon: marker_bpp },
        { id: 'school_opec', layer: 'marker', data: [], icon: marker_opec },
        { id: 'school_onab', layer: 'marker', data: [], icon: marker_onab },
        { id: 'school_bkk', layer: 'marker', data: [], icon: marker_bkk },
      )
    }
    setDataMap(arr_map)
    let data_legend = []

    if (lang === 'EN') {
      data_legend = [
        { name: `${filter.jontype === 'exjon' ? 'poorest' : 'poor'}`, value: response.data.reduce((acc, cur) => acc + cur[filter.jontype], 0), unit: 'คน' },
        { name: 'percent', value: ((response.data.reduce((acc, cur) => acc + cur[filter.jontype], 0) / response.data.reduce((acc, cur) => acc + cur.target, 0)) * 100).toFixed(2), unit: '%' },
      ]
    } else {
      data_legend = [
        { name: `นร.${filter.jontype === 'exjon' ? 'ที่ได้รับทุนเสมอภาค' : 'ยากจน'}`, value: response.data.reduce((acc, cur) => acc + cur[filter.jontype], 0), unit: 'คน' },
        { name: 'คิดเป็น', value: ((response.data.reduce((acc, cur) => acc + cur[filter.jontype], 0) / response.data.reduce((acc, cur) => acc + cur.target, 0)) * 100).toFixed(2), unit: '%' },
      ]
    }

    setLegendData({
      ...legend_data,
      title: T(filter.province_name),
      subtitle: T(filter.city_name),
      data: data_legend,
    })

  }, [filter, loading, user_roles, lang])

  const convertMapContent = (filter, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => {
      let obj = { province: item, total: 0, target: 0, popup: 'cct' }
      obj[filter.jontype] = 0
      return obj
    });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => {
      let obj = { province: item.split('_')[0], city: item.split('_')[1], total: 0, target: 0, popup: 'cct' }
      obj[filter.jontype] = 0
      return obj
    });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => {
      let obj = { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, target: 0, popup: 'cct' }
      obj[filter.jontype] = 0
      return obj
    });
    if (filter.sungkud !== 'ทั้งหมด') data = data.filter(item => item.sungkud === filter.sungkud)
    data.forEach((item) => {
      let total = (item.total_student)
      let target = (item.total_target)
      let cct = (item['total' + '_' + filter.jontype])
      if (filter.educlass !== 'ทั้งหมด') {
        target = item[filter.educlass]
        if (item.sungkud === 'สพฐ.' && (filter.educlass === 'h')) target = 0
        if (item.sungkud === 'กทม.' && (filter.educlass === 'h')) target = 0
        if (item.sungkud === 'อปท.' && (filter.educlass === 'h')) target = 0
        // if (item.sungkud === 'ตชด.' && (filter.educlass === 'h')) target = 0
        if (item.sungkud === 'พศ.' && (filter.educlass === 'k' || filter.educlass === 'm' || filter.educlass === 'h')) target = 0
        cct = item[filter.educlass + '_' + filter.jontype]
      }
      if (filter.view === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {
          !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
          !province[index]['target'] ? province[index]['target'] = target : province[index]['target'] += target
          !province[index][filter.jontype] ? province[index][filter.jontype] = cct : province[index][filter.jontype] += cct
        }
        res = province
      } else if (filter.view === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
          !city[index]['target'] ? city[index]['target'] = target : city[index]['target'] += target
          !city[index][filter.jontype] ? city[index][filter.jontype] = cct : city[index][filter.jontype] += cct
        }
        res = city
      } else if (filter.view === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === item.tumbon_name)
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['target'] ? tumbon[index]['target'] = target : tumbon[index]['target'] += target
          !tumbon[index][filter.jontype] ? tumbon[index][filter.jontype] = cct : tumbon[index][filter.jontype] += cct
        }
        res = tumbon
      } else if (filter.view === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === item.tumbon_name)
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['target'] ? tumbon[index]['target'] = target : tumbon[index]['target'] += target
          !tumbon[index][filter.jontype] ? tumbon[index][filter.jontype] = cct : tumbon[index][filter.jontype] += cct
        }
        res = tumbon
      }
    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element[filter.jontype] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element[filter.jontype] / result.map(item => item[filter.jontype]).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item[filter.jontype])), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item[filter.jontype])), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item[filter.jontype])), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]

    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element[filter.jontype])
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
      element['percent_cct'] = parseFloat((element[filter.jontype] / element['target'] * 100).toFixed(2))
    })

    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      if (property.type === 'school') {
        setModalSchool({
          visible: true,
          resource: 'destination',
          ...property
        })
      }
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  return (
    <Layout
      isLoading={loading}
      Role={['public', 'public-login', 'partner', 'eef', 'partner_province']}
      backgroundImage={`url(${ImageBG})`}
      getUserRoles={(roles) => setUserRoles(roles)}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full">{T('CCT_Project_TITLE_1')}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full">{T('CCT_Project_TITLE_2')}</h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-xl mb-2 text-[#0E3165]">{T('Project_Description')}</div>
          <p className="text-lg mb-2 font-baijam">{T('CCT_Project_Description')}</p>
          <div className="grid grid-cols-12 gap-x-4 gap-y-8 w-full mt-8">
            <div className="col-span-12 md:col-span-10 lg:col-span-5">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T('Target_Group')}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[150px] lg:h-[145px] p-1">
                <div className="text-md lg:text-lg lx:text-xl text-center">{T('CCT_GROUPS_OF_STUDENTS')}</div>

                <div className='flex flex-row justify-around '>
                  <div className="flex-col self-center mx-1">
                    <p className='text-center m-0 text-[#1A5089] font-bold'>{T("CCT_PRE_TO_MID_SCHOOL")}</p>
                    <div className='flex flex-row justify-around'>
                      <div className='flex flex-col'>
                        <img className="flex self-center" src={logodmc} alt="image for banner" width={'60px'} style={{}} />
                        <p className='text-center'>{T("CCT")}</p>
                      </div>
                      <div className='flex flex-col'>
                        <img className="flex self-center" src={home10} alt="image for banner" width={'60px'} style={{}} />
                        <p className='text-center'>{T("DLA")}</p>
                      </div>
                      <div className='flex flex-col'>
                        <img className="flex self-center" src={logoe} alt="image for banner" width={'60px'} style={{}} />
                        <p className='text-center'>{T("OPEC")}</p>
                      </div>
                      <div className='flex flex-col'>
                        <img className="flex self-center" src={logobkk} alt="image for banner" width={'60px'} style={{}} />
                        <p className='text-center'>{T("BKK")}</p>
                      </div>
                    </div>

                  </div>
                  <div className="flex-col self-center mx-1">
                    <p className='text-center m-0 text-[#1A5089] font-bold'>{T("CCT_PRE_TO_HIGH_SCHOOL")}</p>
                    <div className='flex flex-col'>
                      <img className="flex self-center" src={home8} alt="image for banner" width={'60px'} style={{}} />
                      <p className='text-center'>{T("BPP")}</p>
                    </div>
                  </div>

                  <div className="flex-col self-center mx-1">
                    <p className='text-center m-0 text-[#1A5089] font-bold'>{T("CCT_MID_SCHOOL")} </p>
                    <div className='flex flex-col'>
                      <img className="flex self-center" src={home11} alt="image for banner" width={'50px'} style={{}} />
                      <p className='text-center'>{T("ONAB")}</p>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <h3 className="font-kanit font-bold text-xl mb-2 text-[#0E3165]">{T("Type_Support")}</h3>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[145px] p-4">
                <div className='flex flex-row justify-around '>
                  <div className="flex flex-col">
                    <img className="flex self-center" src={money} alt="image for banner" width={'64px'} style={{}} />
                    {
                      lang === 'EN' ?
                        <>
                          <div className="font-bold text-2xl text-[#048967] text-center">{numberWithCommas(3000)}<span className='text-lg text-black'> {T("Bath_Person_Year")}</span></div>
                          <div className="font-bold text-xl text-center">{T("Support_Living_Expenses")}</div>
                        </>
                        :
                        <>
                          <div className="font-bold text-xl text-center">{T("Support_Living_Expenses")}</div>
                          <div className="font-bold text-2xl text-[#048967] text-center">{numberWithCommas(3000)}<span className='text-lg text-black'> {T("Bath_Person_Year")} </span></div>
                        </>
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-4">
              <h3 className="font-bold text-xl mb-2 text-[#0E3165]">{T("CCT_OUTCOMES_PROJECT")}</h3>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[145px] p-4 flex flex-row">

                <div class="flex flex-col flex-1 justify-center">
                  <div className="flex justify-center">
                    <img src={children_oosc} alt="cct school" width={'64px'} />
                  </div>

                  {
                    lang === 'EN' ?
                      <>
                        <div className="font-bold text-xl lg:text-2xl text-[#048967] text-center">{numberWithCommas(data.tun_stdent)} sudents</div>
                        <p className='m-0 text-center text-md'>{T("CCT_STUDENTS_FINANCIAL_SUPPORRT")}</p>
                      </>
                      :
                      <>
                        <p className='m-0 text-center text-md'>{T("CCT_STUDENTS_FINANCIAL_SUPPORRT")}</p>
                        <div className="font-bold text-xl lg:text-2xl text-[#048967] text-center">{numberWithCommas(data.tun_stdent)} คน</div>
                      </>
                  }

                </div>
                <div className="w-[2px] h-full bg-gray-200 m-1">
                </div>
                <div class="flex flex-col flex-1 justify-center">
                  <div className="flex justify-center">
                    <img src={image_school} alt="cct school" width={'64px'} />
                  </div>

                  {
                    lang === 'EN' ?
                      <>
                        <div className="font-bold text-xl lg:text-2xl text-[#048967] text-center">{numberWithCommas(data.join_school)} Schools</div>
                        <p className='m-0 text-center text-md'>{T("CCT_SCHOOL_IN_PROJECT")}</p>
                      </>
                      :
                      <>
                        <p className='m-0 text-center text-md'>{T("CCT_SCHOOL_IN_PROJECT")}</p>
                        <div className="font-bold text-xl lg:text-2xl text-[#048967] text-center">{numberWithCommas(data.join_school)} โรงเรียน</div>
                      </>
                  }

                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("CCT_NOSTDUENT_BY_SCHOOL")}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[10px] mx-auto rounded-xl shadow-xl h-[375px]">
                <div class="grid grid-cols-8 divide-x border-b-[1px] border-b-solid border-b-[#a3a3a3] p-[10px]">
                  <div className=" text-black text-center text-md lg:text-lg col-span-2">{T("School_affiliation")}</div>
                  <div className=" text-black text-center text-md lg:text-lg col-span-3">{T("NO_SCHOOL_SCHOLALARSHIPS")}</div>
                  <div className=" text-black text-center text-md lg:text-lg col-span-3">{T("NO_STUDENTS_SCHOLARSHIPS")}</div>
                </div>
                {
                  data.dataTable.map(item => (
                    // < div className="flex justify-between items-center p-[10px]" >
                    <div class="grid grid-cols-5 p-[10px]">
                      <div className="flex-1 text-black text-lg">{T(item.sungkud)}</div>
                      <div className="flex-1 text-[#1A7E5A] text-right text-md lg:text-lg px-1 font-bold col-span-2">{numberWithCommas(item.school)}</div>
                      <div className="flex-1 text-[#1A7E5A] text-right text-md lg:text-lg px-1 font-bold col-span-2">{numberWithCommas(item.student)}</div>
                    </div>
                  ))
                }

              </div>
            </div>


            <div className="col-span-12 lg:col-span-6">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("CCT_NOSTDUENT_BY_GRADELEVEL")}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[375px] p-4">
                <Chart
                  options={{
                    legend_enabled: false,
                    tooltip: {
                      shared: true,
                      crosshairs: true
                    },
                    yAxis: {
                      title: {
                        text: T("NO_STUDENT")
                      }
                    },
                    tooltip: {
                      shared: true,
                      crosshairs: true
                    },
                    legend: {
                      layout: 'vertical',
                      align: 'right',
                      verticalAlign: 'middle'
                    },
                    title: '',
                    type: 'stackedAndColumn',
                    categories: [T('preschool'), T('primaryschool'), T('middleschool'), T('highschool')]
                  }}
                  containerProps={{ style: { height: "300px" } }}
                  style={{ height: '300px' }}
                  data={data.dataChart.map(x => ({ ...x, name: lang === 'EN' ? x.nameEN : x.nameTH }))}
                />
              </div>
            </div>
          </div>

          <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">
            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>

                <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2 text-[#048967] text-center">

                  {T("CCT_MAP")}

                </p>
                <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                  {
                    lang !== 'EN' &&
                    <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                      onClick={() => navigate('/fullmap-cct')}>
                      สำรวจข้อมูลโครงการ
                      <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  }
                </div>
              </div >
            </header >

            <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>

            <div className="grid grid-cols-11 gap-4 w-full">
              <div className="col-span-11 justify-center">
                <div className="flex flex-col p-0 m-0">
                  < div className="flex flex-col md:flex-row " >
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" > {T("semester")}:</h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="ภาคเรียนที่"
                        // defaultValue={filter.year}
                        value={filter.semester + "/" + filter.year}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, year: val.split('/')[1], semester: val.split('/')[0] })
                        }}
                      >
                        <>
                          {
                            [
                              { value: '1/2567', text: T("S1Y2567") },
                              { value: '2/2566', text: T("S2Y2566") },
                              { value: '1/2566', text: T("S1Y2566") },
                              { value: '2/2565', text: T("S2Y2565") },
                              { value: '1/2565', text: T("S1Y2565") },
                              { value: '2/2564', text: T("S2Y2564") },
                              { value: '1/2564', text: T("S1Y2564") },
                              { value: '2/2563', text: T("S2Y2563") },
                              { value: '1/2563', text: T("S1Y2563") },
                              { value: '2/2562', text: T("S2Y2562") },
                              { value: '1/2562', text: T("S1Y2562") },
                              { value: '2/2561', text: T("S2Y2561") },
                            ]
                              .map(x => <Option value={x.value}>{x.text}</Option>)
                          }
                        </>

                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("TPYES_OF_POVERTY")}: </h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="เลือกประเภทความยากจน"
                        defaultValue="exjon"
                        value={filter.jontype}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, jontype: val })
                        }}
                      >
                        {
                          lang === 'EN' ?
                            <>
                              <Option value={'exjon'}>poorest</Option>
                              <Option value={'jon'}>poor</Option>
                            </>
                            :
                            <>
                              <Option value={'exjon'}>จนพิเศษ</Option>
                              <Option value={'jon'}>ยากจน</Option>
                            </>
                        }


                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("province")}:</h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="เลือกจังหวัด"
                        defaultValue="ทั้งหมด"
                        value={filter.province_name}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, view: (val === 'ทั้งหมด' ? 'country' : 'province'), province_name: val, city_name: null, tumbon_name: null })
                        }}
                      >
                        <Option value={'ทั้งหมด'}>{T('ทั้งหมด')}</Option>
                        {
                          data[`data${filter.year + filter.semester}`] && [...new Set(data[`data${filter.year + filter.semester}`].map(x => x.province_name))]
                            .filter(x => x !== 'ไม่ระบุ')
                            .sort()
                            .map(x => <Option value={x}>{T(x)}</Option>)
                        }
                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("School_affiliation")}: </h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="เลือกสังกัด"
                        defaultValue="ทั้งหมด"
                        value={filter.sungkud}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, sungkud: val })
                        }}
                      >
                        <Option value={'ทั้งหมด'}>{T('ทั้งหมด')}</Option>
                        <Option value={'สพฐ.'}>{T('CCT')}</Option>
                        <Option value={'อปท.'}>{T('DLA')}</Option>
                        <Option value={'ตชด.'}>{T('BPP')}</Option>
                        <Option value={'สช.'}>{T('OPEC')}</Option>
                        <Option value={'พศ.'}>{T('ONAB')}</Option>
                        <Option value={'กทม.'}>{T('BKK')}</Option>
                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("EDU_LEVEL")}: </h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="เลือกช่วงชั้น"
                        defaultValue="ทั้งหมด"
                        value={filter.educlass}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, educlass: val })
                        }}
                      >
                        {
                          lang === 'EN' ?
                            <>
                              <Option value={'ทั้งหมด'}>All</Option>
                              <Option value={'k'}>Pre-Primary</Option>{T('ทั้งหมด')}
                              <Option value={'p'}>Primary</Option>
                              <Option value={'m'}>Junior High School</Option>
                              <Option value={'h'}>High School</Option>
                            </>
                            :
                            <>
                              <Option value={'ทั้งหมด'}>ทั้งหมด</Option>
                              <Option value={'k'}>ก่อนประถมศึกษา</Option>
                              <Option value={'p'}>ประถมศึกษา</Option>
                              <Option value={'m'}>มัธยมศึกษาตอนต้น</Option>
                              <Option value={'h'}>มัธยมศึกษาตอนปลาย</Option>
                            </>
                        }
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-11">
                <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                  <Mapbox
                    id={'map-cct'}
                    data={data_map}
                    zoom={5.2}
                    className="fullmap-cct-container"
                    style={"border_basic"}
                    filter={filter}
                    notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                    onclickBorder={onclickBorder}
                    onClickMarker={onClickMarker}
                    lang={lang}
                  />
                  <div className='absolute top-2 left-2 z-1'>
                    <LegendCard {...legend_data} lang={lang} />
                    <div className='justify-left mt-2 mx-auto'>
                      {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                        <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> {T("back")}
                      </button> : <></>}
                    </div>
                  </div>
                  <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                    <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                      <span className='font-bold center mx-1 mb-2'>{T("PROPORTION_STDUENT_RECEIVED_SCHOLARSHIP", lang)}</span>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("MAX")}
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("MIN")}
                          </div>
                        </div>
                        <div className='ml-4  flex flex-col'>
                          <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("NO_DATA")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    filter.view === "city" && user_roles !== 'public' ?
                      <div className='absolute bottom-[10px] left-[470px] z-1'>
                        <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                          <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษาตามสังกัด</span>
                          <div class="flex flex-row">
                            <div class="flex flex-row text-center mr-2">
                              <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                              <span className='center m-auto'>สพฐ.</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img src={marker_dla} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                              <span className='center m-auto'>อปท.</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                              <span className='center m-auto'>ตชด.</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img src={marker_onab} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                              <span className='center m-auto'>พศ.</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                              <span className='center m-auto'>สช.</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img src={marker_bkk} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                              <span className='center m-auto'>กทม.</span>
                            </div>
                          </div>
                        </div>
                      </div> : <></>
                  }
                </div>
              </div>

            </div>

            <div className='mt-6'>
              <Table
                size="small"
                bordered
                scroll={{ x: 1300, y: 500 }}
                // pagination={{ pageSize: 12 }}
                columns={[
                  {
                    title: map_key_group[filter.view + '_' + lang],
                    dataIndex: 'key',
                    key: 'key',
                    fixed: 'left',
                    align: 'center',
                    width: 150,
                    fixed: 'left',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "left" }
                        },
                        children: (filter.view === 'city' ? <p>{T(text)}</p> : <a onClick={() => {
                          if (filter.view === 'country') {
                            setFilter({
                              ...filter,
                              ...{
                                province_name: text,
                                view: 'province',
                                city_name: null,
                              }
                            })
                          } if (filter.view === 'province') {
                            setFilter({
                              ...filter,
                              ...{
                                view: 'city',
                                city_name: text,
                              }
                            })

                          }

                        }}>{T(text)}</a>)
                      };
                    },
                    sorter: (a, b) => {
                      if (a.key > b.key) {
                        return -1;
                      }
                      if (b.key > a.key) {
                        return 1;
                      }
                      return 0;
                    }
                    //  (a, b) => a.key - b.key,
                  },
                  {
                    title: T("NO_SCHOOL"),
                    dataIndex: 'school',
                    key: 'school',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.school - b.school,
                  },
                  {
                    title: T("TOTAL_NO_STUDENTS"),
                    dataIndex: 'student',
                    key: 'student',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.student - b.student,
                  },
                  {
                    title: T("TOTAL_NO_TARGET_STUDENTS"),
                    dataIndex: 'student_target',
                    key: 'student_target',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.student_target - b.student_target,
                  },
                  {
                    title: T("NO_POOR_STUDENTS"),
                    dataIndex: 'student_jon',
                    key: 'student_jon',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.student_jon - b.student_jon,
                  },
                  {
                    title: T("PO_POOR_STUDENTS"),
                    dataIndex: 'student',
                    key: 'student',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{(record.student_jon / record.student_target * 100).toFixed(2)}%</div>
                      };
                    },
                    sorter: (a, b) => (a.student_jon / a.student_target) - (b.student_jon / b.student_target),
                  },

                  {
                    title: T("NO_POOREST_STUDENTS"),
                    dataIndex: 'student_exjon',
                    key: 'student_exjon',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.student_exjon - b.student_exjon,
                  },
                  {
                    title: T("PO_POOREST_STUDENTS"),
                    dataIndex: 'student',
                    key: 'student',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{(record.student_exjon / record.student_target * 100).toFixed(2)}%</div>
                      };
                    },
                    sorter: (a, b) => (a.student_exjon / a.student_target) - (b.student_exjon / b.student_target),
                  },
                  // {
                  //   title: T("NO_POOR_POOREST_STUDENTS"),
                  //   dataIndex: 'sum_jon_exjon',
                  //   key: 'sum_jon_exjon',
                  //   align: 'center',
                  //   render(text, record) {
                  //     return {
                  //       props: {
                  //         style: { textAlign: "right" }
                  //       },
                  //       children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                  //     };
                  //   },
                  //   sorter: (a, b) => a.sum_jon_exjon - b.sum_jon_exjon,
                  // },
                  // {
                  //   title: T("PO_POOR_POOREST_STUDENTS"),
                  //   dataIndex: 'student',
                  //   key: 'student',
                  //   align: 'center',
                  //   render(text, record) {
                  //     return {
                  //       props: {
                  //         style: { textAlign: "right" }
                  //       },
                  //       children: <div>{(record.sum_jon_exjon / record.student_target * 100).toFixed(2)}%</div>
                  //     };
                  //   },
                  //   sorter: (a, b) => (a.sum_jon_exjon / a.student_target) - (b.sum_jon_exjon / b.student_target),
                  // }
                ]}
                dataSource={dataTableCCT}
              >
              </Table>
            </div>
            <RefDataDate
              // *Equity Scholarship  Number of students receiving support under OBEC, DLA, BPP, ONAB, and OPEC (2022)
              title={
                lang === 'EN' ?
                  '*Equity Scholarship  Number of students receiving support under OBEC, DLA, BPP, ONAB, OPEC, and BKK (2024)' :
                  '*ทุนเสมอภาค ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ภาคเรียนที่ 1/2562 - 1/2567'
              }
              subjectTitle={
                lang === 'EN' ?
                  '*Processed on December 2024' :
                  '*ประมวลผล ณ เดือนธันวาคม 2568'
              }
            />
          </div >
        </div >
        <div className='h-[100px]'></div>
      </div>
    </Layout >
  )
}

export default PageCCT

